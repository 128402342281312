import React from "react";
import NavItem from "../nav-item";

const Footer = () => {
  return (
    <footer className="bg-bg flex flex-col lg:flex-row gap-5 lg:justify-between lg:items-center px-7 pt-16 md:pt-24 pb-16">
      <div className="">
        <h1 className="text-sky-blue font-[800] text-xl">URGE</h1>
        <p className="hidden sm:block text-white leading-8 lg:w-[300px] mt-4 text-sm lg:text-base">
          Urge talks is a Network predicated on amplifying voices and
          expressions, usually in the form of short, powerful videos and talks
        </p>
      </div>
      <div className="text-white lg:text-lg">
        <h1 className="mb-6 text-sky-blue font-[700]">LINKS</h1>
        <ul className="flex flex-col gap-5 font-[600]">
          <NavItem
            title="Privacy"
            link="/privacy"
          />
          <NavItem
            title="Download Android App"
            link="https://play.google.com/store/apps/details?id=com.urgetalks.urge"
            newpage
          />
          <NavItem
            title="Download iOS App"
            link="https://apps.apple.com/us/app/urge/id6474566609"
            newpage
          />
          <NavItem
            title="Premium Pricing"
            link="/pricing"
          />
          <NavItem
            title="Career"
            link="/job"
          />
         
         {/* COLLABORATION */}
           <NavItem
              title="Collaboration"
              link="/collab"
            />
        </ul>
      </div>
      <div className="text-white text-lg">
        <ul className="flex flex-col gap-5 font-[600]">
          <NavItem
            title="Urge Store"
            link="/store"
          />
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
