import React from "react";
import Nav from "../../components/nav";
import Footer from "../../components/footer";
import JobPosting from "./JobPosting";
import { useGetJobPosting } from "../../hooks/job.hook";
import './styles.css'; 

const Register = () => {
  const { data, isFetching } = useGetJobPosting();
  const jobPostings = data?.data?.data || []; // Default to an empty array if undefined

  return (
    <>
      <div className="bg-bg px-7 md:px-14 pt-9">
        <Nav />
        <h1 className="text-3xl font-bold text-center mb-6 text-white">Career</h1>
        {!isFetching ? (
          jobPostings.length > 0 ? (
            <div className="job-postings-grid">
              {jobPostings.map((job, index) => (
                <JobPosting
                  key={index}
                  id={job.id}
                  title={job.title}
                  company={job.company}
                  description={job.description}
                  requirements={job.job_requirements}
                  benefits={job.benefits}
                  image={job.image}
                />
              ))}
            </div>
          ) : (
            <p className="text-center text-gray-500">No jobs found.</p>
          )
        ) : (
          <p className="text-center text-gray-500">Loading job postings...</p>
        )}
        <Footer />
      </div>
    </>
  );
};

export default Register;
